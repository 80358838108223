import React, { useState, useEffect, useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions, InfoBoxItem } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import Globe from 'react-globe.gl';


//====== custom plugin
import useGraphData from 'hooks/useGraphData';
import { InfoBox } from './InfoBox'
import { MessageModal } from './MessageModal';
import { TimeframeInfo } from './TimeframeInfo';
import { PolynetNodeObject3D, scaleValue } from 'nodeutils';
import { Loading } from './Loading';
import 'style.css';
import { globalFlags } from 'globalflags';
//====

interface Props extends PanelProps<SimpleOptions> { }

const getStyles = () => ({
  wrapper: css`
  font-family: Open Sans;
  position: relative;
`,
  svg: css`
  position: absolute;
  top: 0;
  left: 0;
`,
  textBox: css`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
`,
});

const NO_DATA_WITHIN_30_DAYS = "There is no data to display for the selected end of time interval.";
const NO_DATA_OUT_OF_RANGE = "Please select an interval ending within the last 30 days for this chart.";
const GRAPH_LIMIT_ALERT = "The timeframe has been reduced to improve the plugin performance";

//====== External Map Images urls
// conventional: https://unpkg.com/three-globe/example/img/earth-night.jpg
// Black marble 01 deg: https://s73-research-lab.s3.eu-west-1.amazonaws.com/GlobeGL/BlackMarble_2016_01deg.jpg
// Black marble 3km (High Resolution - 7MB): https://s73-research-lab.s3.eu-west-1.amazonaws.com/GlobeGL/BlackMarble_2016_3km.jpg
// Black marble 3km (compressed 2.4MB): https://s73-research-lab.s3.eu-west-1.amazonaws.com/GlobeGL/BlackMarble_2016_3km_compress.jpg
// Black marble 3km v1 (compressed 1.5MB): https://s73-research-lab.s3.eu-west-1.amazonaws.com/GlobeGL/BlackMarble_2016_3km_compress_v1.jpg
//====
const externalMapUrl = 'https://s73-research-lab.s3.eu-west-1.amazonaws.com/GlobeGL/BlackMarble_2016_3km_compress_v1.jpg';

const OPACITY = 0.5;

export const SimplePanel: React.FC<Props> = ({ width, height, data }) => {
  const styles = useStyles2(getStyles);
  const primaryFieldName = 'SessionId';
  const { graph, graphDateInfo, monthTimeRange} = useGraphData(data, primaryFieldName);
  const { graphData, graphDataLimited, mapCenterInfo, arcGraphData, minBH, maxBH} = graph;
  const { graphDateFromInfo, graphDateToInfo } = graphDateInfo
  const globeRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [infoBoxData, setInfoBoxData] = useState<InfoBoxItem[]>([]);

  const onGlobeReadyCallback = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (graphData.nodes.length <= 1) {
      setNoDataFlag(true);
    } else {
      setNoDataFlag(false);
    }
  }, [graphData]);

  useEffect(() => {
    if (noDataFlag === true) {
      if (monthTimeRange === true) {
        setNoDataMessage(NO_DATA_WITHIN_30_DAYS);
      } else {
        setNoDataMessage(NO_DATA_OUT_OF_RANGE);
      }
    } else {
      setNoDataMessage('');
    }
  }, [noDataFlag, monthTimeRange]);

  useEffect(() => {
    if (graphDateFromInfo !== '' && graphDateToInfo !== '' && !noDataFlag) {
      setInfoBoxData([
        {
          label: 'Date:',
          value: <TimeframeInfo 
                    startDate={graphDateFromInfo} 
                    endDate={graphDateToInfo} 
                    startDateWarningIcon={graphDataLimited} 
                    endDateWarningcon={false} 
                  />,
          tooltip: graphDataLimited ? GRAPH_LIMIT_ALERT : `${graphDateFromInfo} - ${graphDateToInfo}`,
          showLabel: false
        }
      ])
    } else {
      setInfoBoxData([]);
    }
  }, [noDataFlag, graphDateFromInfo, graphDateToInfo, graphDataLimited]);

  useEffect(() => {
    globeRef.current.pointOfView(mapCenterInfo, 3000);
  }, [mapCenterInfo]);

  const altitudeCallback = (node: object): number => {
    return scaleValue(
      (node as PolynetNodeObject3D).bh,
      minBH, 
      maxBH, 
      globalFlags.minScaling, 
      globalFlags.maxScaling
    );
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
      width: ${width}px;
      height: ${height}px;
    `
      )}
    >
      <Globe
        ref={globeRef}
        globeImageUrl={externalMapUrl}
        onGlobeReady={onGlobeReadyCallback}
        pointsData={graphData ? graphData.nodes : []}
        arcsData={arcGraphData}
        arcColor={() => [`rgba(0, 100, 255, ${OPACITY})`, `rgba(0, 255, 0, ${OPACITY})`]}
        arcDashLength={0.25}
        arcDashGap={1}
        arcDashInitialGap={() => Math.random()}
        arcDashAnimateTime={4000}
        pointAltitude={altitudeCallback}
        pointColor="color"
        pointResolution={6}
        pointRadius={0.1}
        width={width}
        height={height}
      />
      {isLoading && !noDataFlag ?
        <Loading /> :
        <>
          <InfoBox items={infoBoxData} />
          <MessageModal show={noDataFlag} message={noDataMessage} />
        </>}
    </div>
  );
};
