import React from 'react';
import { GraphDateRangeInfo } from 'types';
import { IoAlertCircle } from "react-icons/io5";

//====== custom plugin
import 'style.css';
//====

export const TimeframeInfo: React.FC<GraphDateRangeInfo> = ({startDate, startDateWarningIcon, endDate, endDateWarningcon}) => {
  return (
    <>
      {startDate}
      {startDateWarningIcon ? <IoAlertCircle  style={{margin: '0 3px'}} size="1.3rem" color="#4DC3FF" /> : null}
      {' - '}
      {endDate}
      {endDateWarningcon ? <IoAlertCircle style={{margin: '0 3px'}} size="1.3rem" color="#4DC3FF" /> : null}
    </>
  );
};
